//
// Theme style
//

$bk-orange: #FF8631;
$bk-light-orange: #F5EBDC;
$bk-green: #188637;
$bk-red: #D62200;
$bk-brown: #502213;

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import './core/layout/base/layout';
@import 'layout/layout';


// Overwrite

.app-sidebar-toggle {
  background-color: $bk-orange !important;

  i {
    color: #ffffff !important;
  }
}

.app-footer {
  position: absolute;
  left: 0 !important;
  bottom: 0 !important;

  a {
    color: $bk-red !important;
  }
}

.page-heading, .fw-bold {
  font-family: 'Flame Bold', 'sans-serif' !important;
}

table {
  thead {
    tr, tr.bg-light {
      background-color: $bk-brown !important;
      border: none !important;

      th {
        color: $bk-orange !important;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px dashed $bk-brown !important;

      &:last-child {
        border-bottom: 1px solid $bk-brown !important;
      }

      span:not(.svg-icon), span.text-gray-800 {
        color: $bk-brown !important;
      }
    }
  }
}

.table:not(.table-bordered) tfoot tr:last-child, .table:not(.table-bordered) tbody tr:last-child {
  border-bottom: 1px solid $bk-brown !important;
}

.card {
  .card-body {
    padding: 0 !important;
  }
}

.app-header {
  border: none !important;
  //padding-top: 62px !important;
}

.app-wrapper {
  .app-sidebar-logo {
    border-bottom: 1px dashed $bk-brown !important;

    .app-sidebar-logo-default {
      width: 40px !important;
      height: auto !important;
    }

    .app-sidebar-logo-minimize {
      width: 30px !important;
      height: auto !important;
    }
  }

  .app-sidebar-menu {
    .menu {
      .menu-item {
        font-size: 16px !important;
        line-height: 20px !important;

        a:not(.active) {
          color: $bk-brown !important;

          span {
            color: $bk-brown !important;

            i {
              color: $bk-brown !important;
            }
          }
        }
      }
    }
  }
}

.header-user-menu {
  .user-name {
    color: $bk-brown !important;
    font-size: 14px !important;
    line-height: 18px !important;
  }

  .menu-link {
    font-size: 11px !important;
    line-height: 15px !important;
    cursor: pointer;
  }
}

h1, h2, h3, .text-dark {
  color: $bk-brown !important;
}

.pagination {
  .page-item {
    a {
      color: $bk-brown !important;
    }

    &.active {
      a {
        color: #ffffff !important;
      }
    }
  }
}

.card-header {
  border-bottom: 1px solid $bk-brown !important;
}

.card-code-validator {
  .card-header {
    border-bottom: none !important;
  }

  form {
    button {
      background-color: $bk-green !important;
    }
  }
}

form {
  padding: 0 2.25rem;
  margin-bottom: 1.75rem !important;

  .form-label {
    font-family: 'Flame Bold', 'sans-serif' !important;
    color: $bk-brown !important;
    font-size: 17px !important;
    line-height: 21px !important;
  }

  input {
    font-family: Inter, 'sans-serif' !important;
    height: 35px;

    &.form-control {
      background-color: #ffffff !important;
    }
  }

  &.form-code-validator {
    margin-bottom: 0 !important;
    button {
      width: 100%;
      height: 35px;
      font-size: 14px !important;
      line-height: 18px !important;
      text-transform: uppercase;
    }
  }

  button {
    font-size: 14px !important;
    line-height: 18px !important;
  }
}

.app-footer {
  span {
    color: $bk-brown !important;
    font-size: 13px !important;
    line-height: 17px !important;

    &.copy {
      font-family: Inter, 'sans-serif' !important;
    }
  }
}

h1, h1.fs-3 {
  font-size: 38px !important;
  line-height: 42px !important;
}

h3, h3.card-label {
  font-size: 17px !important;
  line-height: 21px !important;
}

.form-info {
  padding: 0 2.25rem;
  padding-top: 1rem;
}

.separator {
  //&.separator-dashed {
    border-bottom-color: $bk-brown !important;
  //}
}

.text-brown {
  color: $bk-brown !important;
}

.alert {
  margin: 15px;
}

// input under it
.css-1nmdiq5-menu {
  z-index: 99999999 !important;
}